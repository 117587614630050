import { Component, inject, isDevMode, OnInit, Renderer2 } from '@angular/core';
import moment from 'moment';
import { AppConfigService } from './app-config.service';
import { AutoLogoutService } from './auto-logout.service';

@Component({
	selector: 'ft-root',
	template: ` <router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
	private _config: AppConfigService = inject(AppConfigService);
	private renderer: Renderer2 = inject(Renderer2);
	private autoLogoutService = inject(AutoLogoutService);

	ngOnInit(): void {
		moment.locale(this._config.appLang);
		this.loadScript('/web-apps/apps/api/documents/api.js');

		this.autoLogoutService.startMonitoring();
	}

	private loadScript(scriptUrl: string): void {
		if (document.getElementById('o_office')) return;
		if (isDevMode()) {
			const { hostname, protocol } = location;
			scriptUrl = `${protocol}//${hostname}:1500${scriptUrl}`;
		}
		const script = this.renderer.createElement('script');
		script.type = 'text/javascript';
		script.src = scriptUrl;
		script.id = 'o_office';

		script.onload = () => {
			console.log(
				'%cFireOffice script loaded successfully.',
				'color: green;font-weight:bold;'
			);
		};

		script.onerror = () => {
			console.error(
				'%cError loading FireOffice script.',
				'color:red;font-weight:bold'
			);
		};

		this.renderer.appendChild(document.head, script);
	}
}
